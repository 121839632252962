<script>
    export default {
        name: "ProductPartnersList",
        props: {
            productId: Number,
            isColorPreloader: Boolean,
            isLast: Boolean,
        },
        data() {
            return {
                partners: [],
                loading: true,
            };
        },
        computed: {
            icons() {
                return {
                    "Яндекс.Маркет": "ym",
                    Wildberries: "wb",
                    OZON: "ozon",
                    "М.Видео": "mvideo",
                    DNS: "dns",
                    "re:Store": "restore",
                    Эльдорадо: "eldorado",
                };
            },
        },
        mounted() {
            if (!this.partners.length) {
                this.getPartnersLinks();
            }
        },
        methods: {
            getPartnersLinks() {
                this.$store.productsApi
                    .getProductPartnerLinks({ productId: this.productId })
                    .then((res) => {
                        this.loading = false;
                        this.partners = res;
                        this.$emit("on-loaded", res.length > 0);
                    });
            },
            getPartnersIcon(name) {
                return `/static/partners/${this.icons[name]}.svg`;
            },
        },
    };
</script>

<template>
    <div class="product-partners-list" :class="{ 'is-last': isLast }">
        <div v-if="!loading && partners.length > 0" class="product-partners-list__title">
            В наличии у партнеров:
        </div>
        <div v-show="loading" class="product-partners-list__preloader">
            <preloader :show="true" :is-color="isColorPreloader" sm-size></preloader>
        </div>
        <div class="product-partners-list__list">
            <a
                v-show="partner.link"
                v-for="partner in partners"
                :key="partner.id"
                :href="partner.link"
                target="_blank"
                class="product-partners-list__item"
            >
                <img :src="getPartnersIcon(partner.name)" :alt="partner.name" />
                <span class="desktop-hidden">{{ partner.name }}</span>
                <div class="product-partners-list__tooltip">{{ partner.name }}</div>
            </a>
        </div>
    </div>
</template>

<style lang="scss">
    .product-partners-list {
        margin-bottom: 24px;

        @include bp($bp-desktop-sm) {
            margin-bottom: 58px;
        }
        &.is-last {
            margin-bottom: 0;
        }
    }

    .product-partners-list__preloader {
        position: relative;
        height: 200px;
    }

    .product-partners-list__title {
        margin-bottom: 16px;
        color: var(--primary-text);
        font-size: 16px;
        line-height: 18px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 24px;
        }
    }

    .product-partners-list__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px -16px;
    }

    .product-partners-list__item {
        display: flex;
        align-items: center;
        width: calc(50% - 8px);
        margin: 0 4px 16px;
        img {
            flex-shrink: 0;
            border-radius: 10px;
        }
        span {
            margin-left: 4px;
            color: #454f5b;
            font-size: 12px;
            line-height: 18px;
        }
        @include bp($bp-mobile-xs) {
            span {
                margin-left: 8px;
            }
            img {
                transition: opacity 0.2s ease-in;
            }
        }
        @include bp($bp-desktop-sm) {
            position: relative;
            width: 48px;
            height: 48px;
            &:hover {
                img {
                    opacity: 0.7;
                }
                .product-partners-list__tooltip {
                    display: block;
                }
            }
        }
    }

    .product-partners-list__tooltip {
        display: none;
        @include bp($bp-desktop-sm) {
            position: absolute;
            top: calc(100% + 16px);
            color: #474747;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
        }
    }
</style>

<script>
    import ProductHeader from "@/components/ProductHeader";
    import ProductPartnersList from "@/components/ProductPartnersList";
    import ProductCardsSlider from "@/components/ProductCardsSlider";

    export default {
        name: "ProductAsideEol",
        props: {
            product: Object,
            listForHeader: Array,
            productsIds: Array,
            sliderBreakpoints: Object,
            marginTop: {
                type: Object,
                default: () => {
                    return { desktop: 48, mobile: 46 };
                },
            },
            hasStickyHeader: {
                type: Boolean,
                default: true,
            },
        },
        components: {
            ProductCardsSlider,
            ProductPartnersList,
            ProductHeader,
        },
        data() {
            let currentOffers = this.product.offers.filter((offer) => offer.available);

            if (currentOffers.length === 0) {
                currentOffers = this.product.offers.filter((offer) => !offer.available);
            }

            return {
                currentOffer: currentOffers[0],
                isVisiblePartners: true,
            };
        },
    };
</script>

<template>
    <div class="product-aside-eol">
        <div class="product-aside-eol__header">
            <product-header
                v-if="hasStickyHeader"
                :product="product"
                :offer="currentOffer"
                :list-anchor="listForHeader"
                :marginTop="marginTop"
            >
                <template #button>
                    <slot name="header-compare"></slot>
                </template>
            </product-header>

            <h1 class="product-aside-eol__title" itemprop="name">
                {{ product.name }}
            </h1>

            <div class="product-aside-eol__notification">
                К сожалению этот товар снят с производства, но вы можете приобрести его у наших
                партнеров или купить более новую версию!
            </div>

            <div
                class="product-aside-eol__controls js-product-aside-controls"
                :class="{ 'has-slot': $slots['body-compare'] }"
            >
                <slot name="body-compare"></slot>
            </div>
        </div>

        <div
            class="product-aside-eol__partners product-aside-eol__section"
            v-show="isVisiblePartners"
        >
            <product-partners-list
                :product-id="product.id"
                is-last
                @on-loaded="isVisiblePartners = $event"
            ></product-partners-list>
        </div>

        <div class="product-aside-eol__section">
            <product-cards-slider
                v-if="productsIds"
                type="eol"
                is-small
                :number-slides="2"
                :list-ids="productsIds"
                :product-id="product.id"
                :breakpoints="sliderBreakpoints"
                title="Возможно, вам понравится"
            ></product-cards-slider>
            <template v-else-if="!productsIds && $slots.slider">
                <div class="product-cards-slider__title">Возможно, вам понравится</div>
                <slot name="slider"></slot>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
    .product-aside-eol {
        .product-header__card-title {
            width: 220px;
        }
        .product-header__button {
            width: 0;
        }
    }

    .product-aside-eol__header {
        margin-bottom: 32px;
        background: #ffffff;
        box-shadow: var(--product-aside-shadow);
        border-radius: var(--product-aside-radius);
        border: var(--product-aside-border);
        padding: var(--product-aside-inner-padding);
        @include bp($bp-desktop-sm) {
            margin-bottom: 40px;
        }
    }

    .product-aside-eol__title {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        @include bp($bp-desktop-sm) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .product-aside-eol__notification {
        padding: 12px;
        color: var(--product-aside-notification-color);
        font-size: var(--product-aside-notification-size);
        font-weight: var(--product-aside-notification-weight);
        line-height: 20px;
        background-color: var(--product-aside-notification-background);
        border-radius: var(--product-aside-notification-radius);
        @include bp($bp-desktop-sm) {
            margin: 0 -12px;
            padding: 16px 12px;
            line-height: 22px;
        }
    }

    .product-aside-eol__controls {
        &.has-slot {
            margin-top: 16px;
            @include bp($bp-desktop-sm) {
                margin-top: 24px;
            }
        }
    }

    .product-aside-eol__section {
        .product-partners-list__title,
        .product-cards-slider__title {
            margin-bottom: 16px;
            color: var(--product-aside-title-color);
            font-size: var(--product-aside-title-size);
            font-weight: var(--product-aside-title-weight);
            line-height: 20px;
            text-transform: var(--product-aside-title-case);
        }
        @include bp($bp-desktop-sm) {
            .product-partners-list__title,
            .product-cards-slider__title {
                margin-bottom: 24px;
                line-height: 30px;
                text-transform: none;
            }
        }
    }

    .product-aside-eol__partners {
        margin-bottom: 32px;
        @include bp($bp-desktop-sm) {
            margin-bottom: 60px;
        }
    }
</style>

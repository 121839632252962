<script>
    import { apiUrl } from "@/utils";

    export default {
        name: "ProductHeader",
        props: {
            product: Object,
            offer: Object,
            listAnchor: Array,
            marginTop: {
                type: Object,
                default: () => {
                    return { desktop: 48, mobile: 46 };
                },
            },
        },
        data() {
            return {
                el: null,
                elWrap: null,
                scrollTopOffset: {},
            };
        },
        computed: {
            currentListAnchor() {
                let updatedArr = [
                    {
                        name: "Описание",
                        hash: "js-anchor-description",
                    },
                    {
                        name: "Характеристики",
                        hash: "js-anchor-parameters",
                    },
                ];
                let reviews = {
                    name: "Отзывы",
                    hash: "js-anchor-reviews",
                };
                let similar = {
                    name: "Рекомендуем",
                    hash: "js-anchor-similar",
                };
                this.listAnchor.forEach((item) => {
                    if (item === "reviews") updatedArr.push(reviews);
                    if (item === "similar") updatedArr.push(similar);
                });
                return updatedArr;
            },
            srcImg() {
                return apiUrl(this.offer.previewImage?.small);
            },
        },
        mounted() {
            window.addEventListener("scroll", this.stickyHeaderScroll);
            this.el = this.$refs.scrollHeader;
            this.elWrap = this.$refs.scrollHeaderWrap;
            this.scrollTopOffset =
                window.innerWidth > 992 ? this.marginTop.desktop : this.marginTop.mobile;
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyHeaderScroll);
        },
        methods: {
            stickyHeaderScroll() {
                //TODO 40 магическое число, переделать в высоту шапки
                const productAsideControlsBottom = document
                    .querySelector(".js-product-aside-controls")
                    .getBoundingClientRect().bottom;

                if (productAsideControlsBottom - 40 < 0) {
                    this.elWrap.classList.add("fix-scroll-block");
                    this.el.parentNode.classList.add("fix-scroll-block-parent");
                    this.elWrap.style.top = this.scrollTopOffset + "px";
                } else {
                    this.elWrap.classList.remove("fix-scroll-block");
                    this.el.parentNode.classList.remove("fix-scroll-block-parent");
                }
            },
            scrollTo(hash) {
                document.querySelector(`.${hash}`).scrollIntoView({
                    behavior: "smooth",
                });
            },
        },
    };
</script>

<template>
    <section class="product-header" ref="scrollHeader">
        <div class="product-header__inner" ref="scrollHeaderWrap" data-fix-on-scroll>
            <div class="product-header__wrapper">
                <div class="product-header__list">
                    <div
                        class="product-header__item"
                        v-for="anchor in currentListAnchor"
                        :key="anchor.id"
                        @click="scrollTo(anchor.hash)"
                    >
                        {{ anchor.name }}
                    </div>
                </div>
                <div class="product-header__card">
                    <div class="product-header__card-img">
                        <img :src="srcImg" :alt="product.name" />
                    </div>
                    <div class="product-header__card-text">
                        <div class="product-header__card-title">
                            {{ product.name }}
                        </div>
                        <div class="product-header__card-price">
                            <slot name="price"></slot>
                        </div>
                    </div>
                    <div class="product-header__card-control">
                        <div class="product-header__button"></div>

                        <slot v-if="product.canCompared" name="button"></slot>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
    .product-header {
        height: 60px !important;
        margin-top: -60px;
        display: block;
        z-index: 1000;
    }

    .product-header__inner {
        left: 0;
        z-index: z("header");
        display: none;
        padding: 4px 0;
        width: 100%;
        background-color: #fff;
        //box-shadow: 0 2px 25px rgba(95, 113, 141, 0.15);
        box-shadow: 0 10px 10px rgba(95, 115, 140, 0.1);

        &.fix-scroll-block {
            display: block;
        }
    }

    .product-header__wrapper {
        margin: 0 auto;
        padding-left: 6px;
        padding-right: 12px;
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 1292px;
            padding: 0 16px;
        }
    }

    .product-header__list {
        display: none;
        @include bp($bp-desktop-sm) {
            display: flex;
        }
    }

    .product-header__item {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: var(--product-header-scroll-text);
        margin-right: 32px;
        cursor: pointer;
        transition: 0.3s ease-in-out color;

        &:hover {
            color: var(--product-header-scroll-text-hover);
        }
    }

    .product-header__card {
        display: flex;
        align-items: center;
    }

    .product-header__card-img {
        position: relative;
        flex-shrink: 0;
        @include box(40px);

        img {
            @include box(100%);
            @include center-absolute(0);
            object-fit: contain;
            font-size: 6px;
        }
    }

    .product-header__card-text {
        margin-left: 8px;
        .product-price__number--old,
        .product-price__label {
            display: none;
            @include bp($bp-mobile-s) {
                display: block;
            }
        }
    }

    .product-header__card-title {
        font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--secondary-text);
        @include bp($bp-mobile-xs) {
            width: 120px;
        }
        @include bp($bp-desktop-sm) {
            width: 174px;
        }
    }

    .product-header__card-control {
        margin-left: 4px;
        display: flex;
        align-items: center;
        @include bp($bp-desktop-sm) {
            margin-left: 34px;
        }
    }

    .product-header__button {
        width: 150px;
        height: 44px;
    }

    .product-header__compare {
        position: fixed;
        right: 12px;
        margin-left: 8px;

        @include bp($bp-desktop-sm) {
            position: static;
        }
    }
</style>

<script>
    import { isMobile, lazyLoad } from "@/utils";
    import TheSlider from "@main/packages/TheSlider";

    export default {
        name: "ProductCardsSlider",

        components: {
            picooc: () =>
                import(/* webpackChunkName: "picooc-card" */ "@picooc/components/ProductCard"),
            "xd-design": () =>
                import(/* webpackChunkName: "xd-card" */ "@xd/components/ProductCard"),
            petoneer: () =>
                import(
                    /* webpackChunkName: "petoneer-card" */ "@petoneer/components/ProductCard"
                ),
            ledger: () =>
                import(/* webpackChunkName: "ledger-card" */ "@ledger/components/ProductCard"),
            "mi-ku": () =>
                import(/* webpackChunkName: "miku-card" */ "@mk/components/ProductCard"),
            chipolo: () =>
                import(
                    /* webpackChunkName: "chipolo-card" */ "@chipolo/components/ProductCard"
                ),
            bivan: () =>
                import(/* webpackChunkName: "bivan-card" */ "@bivan/components/ProductCard"),
            zavtra: () =>
                import(/* webpackChunkName: "zavtra-card" */ "@zavtra/components/ProductCard"),

            TheSlider,
        },
        props: {
            type: String,
            listIds: Array,
            productId: Number,
            numberSlides: Number,
            isSmall: Boolean,
            title: String,
            breakpoints: Object,
        },
        data() {
            return {
                cardsList: [],
                isMobileState: false,
                current: this.$root.$_name,
            };
        },
        computed: {
            options() {
                return {
                    ...this.breakpoints,
                    watchSlidesVisibility: true,
                    slideVisibleClass: "visible",
                };
            },
        },
        mounted() {
            this.isMobileState = this.isMobile(992);

            if (this.listIds.length) {
                this.$store.productsApi
                    .getProductsListById({
                        listIds: this.listIds,
                        excludedId: this.productId,
                        isClone: true,
                    })
                    .then((items) => {
                        this.cardsList = items;
                        this.$emit("on-loaded", items.length > 0);
                    });
            }
        },
        methods: {
            isMobile,
            initLazyLoading() {
                lazyLoad();
            },
        },
    };
</script>

<template>
    <div class="product-cards-slider">
        <div v-if="Object.keys(cardsList).length" class="product-cards-slider__wrapper">
            <h3 class="product-cards-slider__title" v-if="title">{{ title }}</h3>
            <slot name="title"></slot>

            <the-slider
                :name-slider="type"
                :number-slides="numberSlides"
                :is-pagination="false"
                :is-arrow="!isMobileState"
                arrow-position="bottom"
                :is-bullets="true"
                :bullets-options="{ type: 'dot', dynamicBullets: true, clickable: false }"
                :options="options"
                :list="cardsList"
                @on-update="initLazyLoading"
            >
                <component
                    :is="current"
                    slot="swiper-slide"
                    class="swiper-slide"
                    v-for="card in cardsList"
                    :product="card"
                    :key="card.id"
                    :is-small="isSmall"
                ></component>
            </the-slider>
        </div>
    </div>
</template>

<style lang="scss">
    .product-cards-slider {
        margin-bottom: 40px;

        .c-slider__swiper {
            margin: -14px;
            padding: 14px;
        }

        .similar-scrollbar,
        .related-scrollbar {
            @include bp($bp-desktop-sm) {
                display: none;
            }
        }
        .related-dots {
            @include bp($bp-desktop-sm) {
                display: none;
            }
        }

        .related-bottom-wrapper {
            @include bp($bp-desktop-sm) {
                justify-content: space-between;
            }
        }

        .c-slider__arrow {
            svg {
                fill: #182028;
            }
        }
    }
</style>
